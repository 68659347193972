/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  UncontrolledCollapse,
  Row,
  Col
} from "reactstrap";

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <div className="header text-center">
            <h3 className="title">Frequently Asked Questions</h3>
          </div>
          <Row>
          <Col md="12">
            <Button color="primary" id="faq0" style={{ marginBottom: '1rem' }}>
              Need Help Or Have A Question?
            </Button>
            <UncontrolledCollapse toggler="#faq0">
              <Card>
                <CardBody>
                  <ul>
                    <li>Please send all questions to support@thefasttracks.com and we will get back to you within 24-48 business hours.</li>
                  </ul>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </Col>
            <Col md="12">
              <Button color="primary" id="faq1" style={{ marginBottom: '1rem' }}>
                How do I get paid?
              </Button>
              <UncontrolledCollapse toggler="#faq1">
                <Card>
                  <CardBody>
                    <ul>
                      <li>Payouts for any commissions earned in the Perpetual Income Engine will be processed through Clickbank. Clickbank will send you a check or bank wire once you’ve earned enough commissions to get paid. If you have not already provided Clickbank with your banking information you can follow the instructions provided here - <a href="https://support.clickbank.com/hc/en-us/articles/220609947-How-do-I-get-paid-">https://support.clickbank.com/hc/en-us/articles/220609947-How-do-I-get-paid-</a></li>
                      <li>Please note that Clickbank has a rule in place that states they will only process your first commission payment once you have generated 5 sales within your Clickbank account</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq2" style={{ marginBottom: '1rem' }}>
                Do I need to promote any products?
              </Button>
              <UncontrolledCollapse toggler="#faq2">
                <Card>
                  <CardBody>
                    <ul>
                      <li>You do NOT need to do any promotion of any sort. The Infinite Income Engine does all of that work for you via the algorithmic rules we have built into the application. All you have to do is enter your Clickbank nickname and let the Engine go to work for you!
</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq3" style={{ marginBottom: '1rem' }}>
                Do I need to build a website?
              </Button>
              <UncontrolledCollapse toggler="#faq3">
                <Card>
                  <CardBody>
                    <ul>
                      <li>You do NOT need to build a website of any kind in order for the Infinite Income Engine to work it’s magic for you.</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq3-5" style={{ marginBottom: '1rem' }}>
                Does anything need to be installed on my computer?
              </Button>
              <UncontrolledCollapse toggler="#faq3-5">
                <Card>
                  <CardBody>
                    <ul>
                      <li>You do NOT need to download or install any software on your computer.Just like you don’t have to download the movie first to watch a movie on Netflix  the Infinite Income Engine software runs entirely in the cloud.</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq4" style={{ marginBottom: '1rem' }}>
                What do I do after submitting my Clickbank nickname?
              </Button>
              <UncontrolledCollapse toggler="#faq4">
                <Card>
                  <CardBody>
                    <ul>
                      <li>Once you have your Clickbank Nickname added to your account, the Infinite Income Engine will deliver sales and commissions to every user of the application based on the algorithmic rules it’s built on. No other steps are needed in order for the Engine to generate commissions for you!</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq5" style={{ marginBottom: '1rem' }}>
                How long will it take for me to make a sale?
              </Button>
              <UncontrolledCollapse toggler="#faq5">
                <Card>
                  <CardBody>
                    <ul>
                      <li>We can’t predict the exact timeline of when you will see sales made for you, but you won’t have to do any work at all in order for the Engine to generate commissions for you! Once you have your Clickbank Nickname added to your account, the Infinite Income Engine will deliver sales and commissions to every user of the application based upon the algorithmic rules it’s built on.</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq6" style={{ marginBottom: '1rem' }}>
                Having Trouble with Clickbank?
              </Button>
              <UncontrolledCollapse toggler="#faq6">
                <Card>
                  <CardBody>
                    <p>Most issues in Clickbank can usually be fixed by following the steps below.</p>
                    <ul>
                      <li>Do not use a mobile device such as a phone or tablet.</li>
                      <li>Do not connect to the internet via a VPN.</li>
                      <li>Disable any Ad Block or Pop-Up software as they can interfere with the connection to Clickbank.</li>
                      <li>Be sure to use the Chrome browser on a desktop/laptop computer. The Chrome web browser is a free download and you can access it here <a href="https://www.google.com/chrome/">https://www.google.com/chrome/</a></li>
                      <li>Be sure that Chrome browser is in Incognito mode. Incognito mode ensures that you are not using any previously cached variables that could interfere with updating your account. To open an incognito window you will open Chrome and then use the following shortcuts on your keyboard.
                          <br/><br/>PC - CTRL-SHIFT-N
                          <br/>MAC - CMD-SHIFT-N
                          <br/><br/>This will open up a new window with an all black border letting you know that you are in Incognito mode.</li>
                    </ul>
                    <p>If you continue to have an issue after following these recommendations  you will need to contact Clickbank at support@clickbank.com. Please add their address to your email account's contact list to ensure any responses from Clickbank are delivered to your inbox.</p>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Faq;
