/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from 'react-player';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCollapse
} from "reactstrap";

class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h2">Welcome</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div style={{position: "relative", paddingTop: "36.25%", marginLeft: "18%", marginRight: "18%"}}>
                    <ReactPlayer
                      url='https://merlinholmes.wistia.com/medias/16qzj86ol6'
                      controls="true"
                      className="ml-auto mr-auto"
                      style={{position: "absolute", top: 0, left: 0}}
                      width='100%'
                      height='100%'
                    />
                  </div>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Welcome;
